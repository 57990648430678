<script>
  export default {};
</script>

<template>
  <main class="contentView">
    <slot></slot>
  </main>
</template>

<style lang="scss">
  .contentView {
    @include Flex(column, flex-start);
    width: 100%;
    max-width: 100%;
    min-height: 99vh;
    max-height: 99vh;
    padding: 70px 20px;
    margin: 0 auto;
    @media (min-width: 768px) {
      max-width: 1880px;
    }
  }
</style>
