<template>
  <div v-if="$userData && showModal">
    <Modal class="modal__getModel" :isDisableSubmit="false" @onCancel="cancel" @onSubmit="sendData">
      <template v-slot:header>
        <h1 class="modal__title">Asignar modelos</h1>
      </template>
      <template v-slot:body>
        <div class="modal__container">
          <div class="modal__models" v-if="models.length">
            <div class="modal__select" v-for="(model, id) in models" :key="id">
              <div class="modal__artisticName">{{ model.artisticName }}</div>
              <ToggleInput class="modal__toggle" v-b-tooltip.hover :title="message(model.email)" :ref="model.email" :checked="true" />
              <i class="modal__monitor" v-html="modelMonitor(model.monitorIdTemp)" />
            </div>
          </div>
          <div spinner v-else-if="!$usersLoaded"></div>
          <div class="modal__default" v-else>
            No tiene modelos oficiales conectadas.
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/modals/Modal.vue";
import ToggleInput from "@/components/input/ToggleInput.vue";
import { socket } from "@/socket";
export default {
  data() {
    return {
      modelsToAsign: [],
      showModal: false,
    };
  },
  components: {
    Modal,
    ToggleInput,
  },
  computed: {
    models() {
      const officialModels = this.$store.getters["connectedUsers/getModelsInRoom"].filter(
        (model) => model.monitorId === this.$userData._id && model.monitorIdTemp !== this.$userData._id
      );
      return officialModels;
    },
  },
  methods: {
    modelMonitor(id) {
      const monitor = this.$store.state.connectedUsers.users.find((user) => user.isOnline && user._id === id);
      return monitor ? `Monitor asignado <strong>${monitor.user}</strong>` : "Sin monitor asignado";
    },
    sendData() {
      this.modelsToAsign = [];
      for (const model of this.models) {
        if (this.$refs[model.email][0].value) this.modelsToAsign.push(model.email);
      }
      socket.emit("updateModel", { models: this.modelsToAsign, monitor: this.$userData });
      localStorage.removeItem("getModels");
      this.showModal = false;
    },
    message(email) {
      const ref = this.$refs[email];
      if (ref && ref.length) {
        return ref[0].value ? "Asignar Modelo" : "No Asignar Modelo";
      }
    },
    cancel() {
      this.showModal = false;
      localStorage.removeItem("getModels");
    },
  },
  mounted() {
    this.$root.$on("modelFree", () => (this.showModal = true));
    const getModels = localStorage.getItem("getModels");
    if (getModels) this.showModal = true;
  },
};
</script>

<style lang="scss">
.modal {
  &__getModel {
    .modal-g__buttons {
      margin-top: $mpadding;
    }
    .modal-g__container {
      margin: 13px;
      width: 500px;
    }
  }
  &__monitor {
    grid-area: monitor;
    font-size: 12px;
    opacity: 0.7;
    margin-bottom: 6px;
  }
  &__artisticName {
    grid-area: artisticName;
  }
  &__title {
    margin-bottom: $mpadding;
  }
  &__container {
    max-height: 220px;
    overflow-y: auto;
    width: 100%;
  }
  &__select {
    margin-top: $mpadding/2;
    display: grid;
    grid-template-columns: 1fr 100px;
    grid-row-gap: 5px;
    grid-template-areas:
      "artisticName toggle"
      "monitor toggle";
    border-bottom: 1px solid #d8d8d8;
    align-items: center;
  }
  &__default {
    text-align: center;
  }
  &__toggle {
    grid-area: toggle;
    justify-self: flex-end;
    align-self: center;
    margin-bottom: $mpadding/2;
  }
}
</style>
