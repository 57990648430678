<template>
  <div v-if="data" @click="closeSidebar()">
    <b-sidebar id="sidebar-right" class="opacity" title="Notificaciones" right shadow scrollable>
      <div class="sidebar__notifications">
        <router-link to="/notifications">
          <p class="sidebar__showMore" v-if="getProfile().role !== 'model'">
            <i class="fas fa-history"></i>
            Ver historial.
          </p>
        </router-link>
        <div v-for="item in data.allNotify" :key="item._id" v-show="!isLoading">
          <div class="sidebar">
            <div class="sidebar__itemContainer">
              <div class="sidebar__img">
                <Avatar :class="['sidebar__avatar', item.imageNotification ? 'avatarImg' : 'notAvatarImg']" :user="getDataAvatar(item)" />
              </div>
              <div class="sidebar__msg" v-html="showMessageList(item)"></div>
            </div>
            <div class="sidebar__date">
              {{ dateFormat(item.date) }}
            </div>
          </div>
        </div>
        <div class="spinner" v-if="isLoading"></div>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import { dateLocaleFormat, showMessage } from "@/js/utils";
import { mapGetters } from "vuex";
export default {
  props: {
    data: {
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      optionFormat: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      },
      reverseData: null,
    };
  },
  computed: {
    userInitials() {
      return this.data.userName ? this.data.userName.substring(0, 1) : "";
    },
  },
  methods: {
    ...mapGetters("user", { getProfile: "getProfile" }),
    getDataAvatar(item) {
      return { role: item?.role, user: item?.userName, picture: item?.imageNotification, artisticName: item?.userName };
    },
    closeSidebar() {
      if (event.target.classList.contains("bi-x", "bi", "b-icon")) {
        const closeSidebar = false;
        this.$root.$emit("closeSidebar", closeSidebar);
      }
    },
    dateFormat(date) {
      return dateLocaleFormat(date, this.optionFormat);
    },
    showMessageList(item) {
      return showMessage(item, this.$userData);
    },
  },
};
</script>

<style lang="scss">
.sidebar {
  padding: $mpadding/3;
  border-bottom: 1px solid $alto-notify;
  &__avatar {
    height: 3rem;
    width: 3rem;
    flex-shrink: 0;
  }
  &__routerLink {
    justify-content: space-between;
  }
  &__date {
    font-size: 14px;
    color: $lipstick;
    font-family: $sec_font;
    margin-left: 68px;
    font-weight: bold;
  }

  &__itemContainer {
    width: 100%;
    display: flex;
  }

  &__img {
    max-width: 20%;
    display: flex;
    align-items: center;
    margin-left: 10px;
  }

  &__msg {
    max-width: 80%;
    margin-top: 12px;
    align-items: center;
    font-size: 12px;
    font-family: $third_font;
    line-height: 130%;
    margin-left: 10px;
  }

  &__showMore {
    padding: 5px;
    padding-left: 10px;
    border-bottom: 1px solid $alto-notify;
    text-align: left;
  }
}

.b-sidebar.b-sidebar-right {
  width: $mobile_width;
  top: $header_height;
  bottom: 0;
  height: 100%;
  z-index: 10;
  opacity: 0.95;
}

.b-sidebar > .b-sidebar-body {
  background: rgba(255, 255, 255, 0.8);
  overflow: hidden;
  margin-bottom: $header_height;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.opacity .b-sidebar > .b-sidebar-header {
  padding: 12px;
  background: rgba(255, 255, 255, 0.8);
  border-bottom: 1px solid $alto-notify;
  border-top: 1px solid $alto-notify;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.opacity .b-sidebar.b-sidebar-right > .b-sidebar-header .close {
  margin-right: 0;
}

.b-sidebar-header {
  font-size: $label-forms;
  font-weight: normal;
  font-family: $sec_font;
  text-transform: uppercase;
  color: $lipstick;
}

@media (min-width: $mobile_width) {
  .b-sidebar.b-sidebar-right {
    width: 380px;
  }
}
</style>
