<script>
  import { mapActions } from "vuex";
  import { socket } from "@/socket";
  import UserMenu from "@/common/userMenu.vue";
  import NotifySidebar from "./NotifySidebar.vue";

  export default {
    components: { UserMenu, NotifySidebar },
    data() {
      return {
        notify: [],
        status: false,
        isLoading: false,
      };
    },
    computed: {
      onlineUsers() {
        return this.$store.getters["connectedUsers/getOnlineUsers"];
      },
      user() {
        return this.$profile;
      },
      socketIsReady() {
        const socketReady = this.$store.getters["connectedUsers/getSocketState"];
        if (socketReady) {
          this.initSocketListeners();
        }
      },
      showBell() {
        return this.user.role !== "employee" && this.user.city !== "Global" && this.$screen.width >= 768;
      },
    },
    watch: {
      $route(to, from) {
        const el = document.getElementById("sidebar-right");
        if (!el.style.display) {
          this.status = false;
          this.$store.state.user.activeNotification = false;
        }
      },
    },
    methods: {
      ...mapActions("applications", ["setShowApps"]),
      getToOperation({ role }) {
        return role === "superadmin" ? "/occupation" : "/operation";
      },
      permitLog(user) {
        return !["model", "employee"].includes(user.role);
      },
      statusSidebar() {
        const state = !this.status;
        this.$store.state.user.activeNotification = state;
        if (state) {
          this.getNotifications();
          this.resetNotifications();
        }
      },
      async getNotifications() {
        const numberNotifies = 10;
        this.isLoading = true;
        const res = await this.$axios.get(`/notifications/?limit=${numberNotifies}`);
        this.isLoading = false;
        this.notify = res.data;
        this.closeAlerts();
      },
      resetNotifications() {
        localStorage.setItem("acount", 0);
        this.$store.state.user.accountant = 0;
      },
      closeAlerts() {
        this.$root.$emit("closeToast");
      },
      async updateWithModalOpen() {
        const numberNotifies = 10;
        const res = await this.$axios.get(`/notifications/?limit=${numberNotifies}`);
        this.notify = res.data;
        localStorage.setItem("acount", 0);
        this.$store.state.user.accountant = 0;
      },
      initSocketListeners() {
        socket.on("newNotification", async () => this.status && (await this.updateWithModalOpen()));
      },
    },
    beforeMount() {
      this.$root.$on("closeSidebar", (closeSidebar) => {
        this.status = closeSidebar;
        this.$store.state.user.activeNotification = false;
      });
    },
  };
</script>

<template>
  <div class="header" noprint v-bind="socketIsReady">
    <div class="header__content">
      <iconic name="menu" class="header__notifyIcon" @click.native="setShowApps" />

      <div class="header__notify" v-b-toggle.sidebar-right @click="statusSidebar()" v-if="showBell">
        <iconic name="bell" class="header__notifyIcon--bell" />
        <span class="header__notifySize">
          {{ this.$store.state.user.accountant }}
        </span>
      </div>

      <router-link class="header__notify" :to="getToOperation(user)" v-if="permitLog(user)">
        <iconic name="users" class="header__notifyIcon" />
        <span class="header__notifySize">{{ onlineUsers.length }}</span>
      </router-link>

      <AvatarStatus :user="user" :containerWidth="40" :containerHeight="40" @click.native="$refs.menu.toggleMenu()" />
    </div>
    <NotifySidebar :data="notify" :isLoading="isLoading" />
    <user-menu ref="menu" :user="user" />
  </div>
</template>

<style lang="scss">
  .header {
    display: flex;
    justify-content: right;
    width: 100%;
    &__content {
      @include Flex(row, flex-end);
      width: fit-content;
      height: 100%;
      gap: 20px;
      border-left: 1px solid rgba(213, 213, 213, 1);
      padding-left: 16px;
    }
    &__notify {
      position: relative;
      @include Flex(column);
      padding: 6px 2px 2px 2px;
      cursor: pointer;
    }
    &__notifyIcon {
      font-size: 25px;
      cursor: pointer;
      color: $primary-color;
      &--bell {
        color: $primary-color;
        font-size: 23px;
      }
    }
    &__notifySize {
      position: absolute;
      @include Row();
      right: -5px;
      top: -5px;
      min-width: 15px;
      min-height: 15px;
      padding: 0 2px;
      font-size: 10px;
      color: $white;
      background-color: $gray-emperor;
      border-radius: 100%;
    }
  }
</style>
