<script>
  import { mapGetters, mapActions } from "vuex";
  import CounterButtonsContract from "../data/dataButtons.json";
  export default {
    data() {
      return {
        elm: 0,
        counterButtons: CounterButtonsContract,
      };
    },
    components: {
      NewsCardOptions: () => import("../views/main/News/NewsCardOptions.vue"),
      NewsVisor: () => import("../views/main/News/NewsVisor.vue"),
      NewsCommentsVisor: () => import("../views/main/News/NewsCommentsVisor.vue"),
      NewsLikesVisor: () => import("../views/main/News/NewsLikesVisor.vue"),
      NewsReadersVisor: () => import("../views/main/News/NewsReadersVisor.vue"),
      NewsSearch: () => import("../views/main/News/NewsSearch.vue"),
      ModalAssign: () => import("../components/modals/ModalAssign.vue"),
      ModalFinishStreaming: () => import("../components/modals/ModalFinishStreaming.vue"),
      MenuAssign: () => import("../components/modals/MenuAssign.vue"),
      ModalProfile: () => import("../components/modals/ModalProfile.vue"),
      UserPolices: () => import("../components/user/permissions/UserPolices.vue"),
      ModalCounterNextReserv: () => import("../components/modals/nextReservCouter.vue"),
      ChatGroupMonitor: () => import("../components/chat/Chat.vue"),
      ButtonsContainer: () => import("../components/buttons/ButtonsContainer.vue"),
      UserManagementModal: () => import("../components/modals/UserManagementModal.vue"),
      ShowPoll: () => import("@/components/poll/ShowPoll.vue"),
      TemplateModalApps: () => import("@/common/Header/RenderMenuApps.vue"),
    },
    computed: {
      ...mapGetters("polls", ["getPollsToResponse", "getSkipPoll"]),
      ...mapGetters("applications", ["getShowApps"]),
      newsMenu: function() {
        return this.$store.state.news.newsMenu;
      },
      NewsVisor: function() {
        return this.$store.state.news.NewsVisor || this.$route.query.news;
      },
      NewsCommentsVisor: function() {
        return this.$store.state.news.NewsCommentsVisor;
      },
      NewsLikesVisor: function() {
        return this.$store.state.news.NewsLikesVisor;
      },
      NewsReadersVisor: function() {
        return this.$store.state.news.NewsReadersVisor;
      },
      NewsSearch: function() {
        return this.$store.state.news.NewsSearch;
      },
      ModalAssign: function() {
        return Object.keys(this.$store.state.user.usersToAssign || {}).length;
      },
      showModalFinish: function() {
        let showModal = this.$store.state.user.ModelFinish;
        return Object.keys(showModal || {}).length;
      },
      MenuAssign: function() {
        let showMenu = this.$store.state.user.menuAssign;
        return Object.keys(showMenu || {}).length;
      },
      ModalProfile: function() {
        return this.$route.name === "assignments";
      },
      ModalCounter: function() {
        return this.$store.state.user.secNextReserv > 0 && this.$store.state.user.showAlertNextReserv;
      },
      showChatGroups() {
        return ["monitor", "superadmin"].includes(this.$userData.role);
      },
      toShowInMagnament: function() {
        return this.$store.state.user.toShowInMagnament;
      },
      toShowPoll: function() {
        this.elm++;
        return this.getPollsToResponse;
      },
      toSkipPoll: function() {
        return this.getSkipPoll.success;
      },
      showAppsModal() {
        return this.getShowApps;
      },
      validateSkipPollTime: function() {
        const todayDate = new Date();
        const localStorageDate = localStorage.getItem("pollSkipTime");
        if (localStorageDate) {
          const savedDate = localStorageDate;
          return this.getPollsToResponse.canSkip && savedDate.getTime !== todayDate;
        }
      },
    },
    methods: {
      ...mapActions("applications", ["setShowApps"]),
    },
  };
</script>

<template>
  <div class="view-controller">
    <NewsVisor v-if="NewsVisor" />
    <NewsCommentsVisor v-if="NewsCommentsVisor" />
    <NewsLikesVisor v-if="NewsLikesVisor" />
    <NewsReadersVisor v-if="NewsReadersVisor" />
    <NewsSearch v-if="NewsSearch" />
    <NewsCardOptions v-if="newsMenu" />
    <ModalAssign v-if="ModalAssign" />
    <ModalFinishStreaming v-if="showModalFinish" />
    <MenuAssign v-if="MenuAssign" />
    <ModalProfile v-if="ModalProfile" />
    <UserPolices v-if="$store.state.user.adminPermissions" />
    <ModalCounterNextReserv v-if="ModalCounter" />
    <ChatGroupMonitor v-if="showChatGroups" />
    <ButtonsContainer v-if="counterButtons.length" />
    <UserManagementModal v-if="toShowInMagnament" />
    <ShowPoll v-if="toShowPoll && validateSkipPollTime && !toSkipPoll" :poll="toShowPoll" :key="elm" />
    <TemplateModalApps :isVisible="getShowApps" :closeM="setShowApps" />
  </div>
</template>
